@font-face {
  font-family: 'JLR Emeric Regular';
  src: url("./jlremeric-regular.woff2");
  format: ("woff2");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'JLR Emeric ExtraLight';
  src: url("./jlremeric-extralight.woff2");
  format: ("woff2");
  font-display: swap;
  font-weight: 100;
}